<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-sm table-bordered text-center table-striped" id="staff_list">
          <thead>
          <tr>
            <th style="width: 5%">SL</th>
            <th>Name</th>
            <th>Phone No.</th>
            <th>Hub</th>
            <th>Joining Date</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(staff, index) in staffs" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ staff.name }}</td>
            <td>{{ staff.phone_no }}</td>
            <td>{{ staff.hub ? staff.hub.name : 'N/A' }}</td>
            <td>{{ staff.joining_date }}</td>
            <td>
              <a class="btn btn-sm btn-info mr-2" href="javascript: void(0);" @click.prevent="view(staff), $bvModal.show('view-staff-modal')"><i class="fe fe-info mr-2"></i>View</a>
              <router-link :to="{ name: 'staffDetails', params: { staff_id: staff.id } }" class="btn btn-sm btn-success mr-2" tag="a">
                <i class="fe fe-eye"/> Details
              </router-link>
              <router-link :to="{ name: 'staffUpdate', params: { staff_id: staff.id } }" class="btn btn-sm btn-primary mr-2" tag="a">
                <i class="fe fe-edit"/> Update
              </router-link>
              <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(staff.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="staffs.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
    <view-staff :staff="staff"></view-staff>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import viewStaff from '@/views/staff/staffView'

export default {
  name: 'List',
  components: { Empty, viewStaff },
  data() {
    return {
      staffs: [],
      staff: {},
      view_room: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getSupportStaffs()
  },
  methods: {
    getSupportStaffs() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/staffs')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.staffs = response.data.staffs
        })
        .catch(error => {
          console.log(error)
        })
    },
    view(staff) {
      this.staff = {}
      this.staff = staff
    },
    onDelete(staffId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/staffs/delete/' + staffId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getSupportStaffs()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
